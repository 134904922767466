/* token相关的方法 */
const TOKEN_KEY = 'token';
const ORI_TOKEN_KEY = 'oriToken';

// 判断用户是否登录
const isLogin = () => {
  return !!localStorage.getItem(TOKEN_KEY);
};

// 获取token
const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

// 设置token
const setToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(ORI_TOKEN_KEY, token);
};

// 清除token
const clearToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(ORI_TOKEN_KEY);
};

export { isLogin, getToken, setToken, clearToken };
