import { defineStore } from 'pinia';

//该store用于配置有关显示的全局参数

const useStore = defineStore('globalParameters', {
  state: () => {
    return {
      windowWidth: 0, //窗口宽度
      windowHeight: 0, //窗口高度
      isLoading: false, //是否开启全局loading,用于请求加载
      loadingTip: '' //全局loading的提示
    };
  },
  getters: {
    arcoSize: (state) => {
      //动态计算arco全局size
      const { windowWidth } = state;
      if (windowWidth > 2000) {
        return 'large';
      } else if (windowWidth > 1600) {
        return 'medium';
      } else if (windowWidth > 1200) {
        return 'small';
      } else {
        return 'mini';
      }
    },
    getIsLoading(state) {
      return this.isLoading;
    }
    //'mini' | 'small' | 'medium' | 'large'
  },
  actions: {
    getWindoSize() {
      //获取窗口宽高
      const width =
        document.documentElement.clientWidth || document.body.clientWidth;
      const height =
        document.documentElement.clientHeight || document.body.clientHeight;
      this.windowWidth = width;
      this.windowHeight = height;
      const fontsize = (width <= 200 ? 1200 : width) / 100 + 'px';
      // @ts-ignore
      document.getElementsByTagName('html')[0].style['font-size'] = fontsize;
    },
    setLoadingConfig(isLoading: boolean, loadingTip?: string) {
      //设置全局loading
      this.loadingTip = loadingTip ? loadingTip : '';
      this.isLoading = isLoading;
    }
  }
});

export default useStore;
