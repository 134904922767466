import axios from 'axios';
import type { AxiosRequestConfig } from 'axios';
import { localGetToken } from '@/utils/localStorage/account';
import { getToken } from '@/utils/auth';
import { Message } from '@arco-design/web-vue';
import useGlobalParametersStore from '@/store/modules/globalParameters';
import { cloneDeep } from 'lodash';
import DuplicateRequest from './duplicateRequest';
import { isJSON } from './isType';

const duplicateRequest = new DuplicateRequest();
const reconnectNumber = 0;

// import useGlobalParametersStore from '@/stores/globalParameters';
// const globalParameters = useGlobalParametersStore();
let globalParameters: any; //全局显示store

const env = import.meta.env;
const service = axios.create({
  timeout: 100000, // 请求超时时间
  baseURL: env.VITE_APP_BASE_URL,
  transformResponse: [
    function (data) {
      if (!isJSON(data)) return data;
      return JSON.parse(data);
    }
  ]
});

type YXRequestConfig = AxiosRequestConfig & {
  headers?: any;
  noToken?: boolean; // 是否不需要token，默认false
  isLoading?: boolean; // 是否请求时开启全局Loading，默认false
  loadingTip?: string; // 全局Loading的提示
};

// axios请求前拦截
service.interceptors.request.use(
  async (config: YXRequestConfig) => {
    config.headers['Content-Type'] =
      config.headers['Content-Type'] || 'application/json;charset=UTF-8';
    if (!config.noToken) {
      // 判断是否需要token
      config.headers.Authorization = `${getToken()}`;
    }
    duplicateRequest.removePendingRequest(config);
    duplicateRequest.addPendingRequest(config);

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

type MsgType = 'success';

// axios请求后拦截
service.interceptors.response.use(
  function (response: any) {
    // globalParameters.setLoadingConfig(false); // 请求完成，关闭全局Loading
    // const { msgType, msg }: { msgType?: MsgType; msg: string } = response.data; // 检查msgType发送Message
    // if (msgType) Message[msgType](msg);
    if (response.config.baseURL === '/v3') {
      // newCode =
      if (response.data.status === '1') {
        response.code = 200;
        response.data.code = 200;
      } else {
        response.code = 500;
        response.data.code = 500;
      }
    }
    const {
      msg,
      code,
      msgType
    }: { msg: string; code: number; msgType: string } =
      response.data || response;
    // if (code === 200 && msgType !== 'get') Message.success(`${msg}`);
    // else if (code >= 400) Message.error(`${msg}`);
    if (code > 400) Message.error(`${msg}`);
    const methodss = ['post', 'put', 'delete'];
    // if (code === 200 && methodss.includes(`${response.config.method}`))
    //   Message.success(`${msg}`);
    if (code === 400) {
      // 400是查重接口
      Message.error(`${msg}`);
      return response;
    }
    if (
      response.headers['content-type'].includes(
        'application/json; charset=utf-8'
      )
    )
      return response.data;
    else return response;
  },
  function (error) {
    if (error?.code === 'ERR_CANCELED') {
      return Promise.reject({ code: error?.code, data: error.message });
    }
    // globalParameters.setLoadingConfig(false); // 请求完成，关闭全局Loading
    // console.log('error', error);
    const { status, msg, code, msgType, mesData } = error.response || error;
    // const status = error.response.status;
    // const code = error.response.data.code;
    // const msg = error.response.data?.msg;
    // const mesData = error.response?.data;
    // const { msg, code }: { msg: string; code: number } = error;
    if (code === 1001) {
      Message.error(`${msg}`);
    } else if (status === 500) {
      Message.error(`${status} :请求失败,请检查网络设置`);
    } else if (status === 401) {
      Message.error(`${status} :请重新登录`);
      localStorage.removeItem('token');
      localStorage.removeItem('oriToken');
    } else if (status === 400) {
      Message.error(`${mesData}`);
    } else {
      Message.error(`${status} :请求失败,请重新尝试`);
    }
    return Promise.reject(error);
  }
);

// axios封装
export async function ajaxService<T = any>(
  config: YXRequestConfig,
  MessageInfo?: {
    message: string;
    mode: 'info' | 'success' | 'warning' | 'error';
  }
) {
  globalParameters = useGlobalParametersStore();
  const { isLoading, loadingTip } = config;
  // if (config.isLoading) {
  // 判断是否打开全局Loading
  globalParameters.setLoadingConfig(isLoading, loadingTip);
  // }
  globalParameters = useGlobalParametersStore();
  globalParameters.setLoadingConfig(true, '请耐心等待');
  try {
    const res: { data: T; code: number } = await service(config);
    if (res.code === 200 || !res.code) {
      duplicateRequest.changeRequest<T>(config, cloneDeep(res.data), true);
    } else {
      duplicateRequest.changeRequest(config, cloneDeep(res.data), false);
    }
    globalParameters.setLoadingConfig(false);
    // 请求完成，关闭全局Loading
    if (MessageInfo && res.code === 200)
      Message[MessageInfo.mode || 'success'](MessageInfo.message || '');
    return Promise.resolve<T>(cloneDeep(res.data));
  } catch (err: any) {
    try {
      if (err.code === 'ERR_CANCELED') {
        const data: T = await err.data;
        return Promise.resolve(data);
      }
    } catch (error) {
      await service(config);
    }

    globalParameters.setLoadingConfig(false); // 请求完成，关闭全局Loading
    return Promise.reject(err);
  }
}
